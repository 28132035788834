import type { FC } from "react";
import React from "react";
import styled from "@emotion/styled";

const StyledSvg = styled.svg`
  transform: scale(1);
  transition: transform 0.14s ease-in-out;

  &:active:hover {
    transform: scale(0.9);
  }
`;

const CameraButtonIcon: FC = () => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="69"
      height="69"
      fill="none"
      viewBox="0 0 69 69"
    >
      <circle cx="34.5" cy="34.5" r="34.5" fill="#D9D9D9" />
      <circle
        cx="34.497"
        cy="34.501"
        r="29.276"
        stroke="#000"
        strokeWidth="2"
      />
    </StyledSvg>
  );
};

export default CameraButtonIcon;
