import type { FC } from "react";
import React, { useEffect } from "react";
import { useBloc } from "src/state/state";
import styled from "@emotion/styled";
import CameraButtonIcon from "src/ui/components/CameraPreviewFrame/CameraButtonIcon";
import {
  IconFlashLightningDisabledTurnedOff,
  IconFlashLightningEnabledTurnedOn
} from "src/constants/icons";
import CameraPreviewBloc from "src/ui/components/CameraPreviewFrame/CameraPreviewBloc";

import Translate from "src/ui/components/Translate/Translate";

const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  height: 100svh;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999910;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.22s ease-in-out;
  --w: min(500px, calc(100vw - 4rem));

  &[data-show="true"] {
    opacity: 1;
    pointer-events: auto;
  }
`;

const CameraTarget = styled.div`
  height: 100vh;
  height: 100svh;
  width: 100vw;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 0.22s ease-in-out;

  video {
    display: block;
    object-fit: contain;
    width: var(--w);
    aspect-ratio: var(--preview-ratio, 1);
    position: absolute;
    top: var(--preview-y, 0);
    left: var(--preview-x, 0);
    transform: rotateY(180deg);
  }

  [data-loaded="false"] & {
    opacity: 0;
  }
`;

const Frame = styled.div`
  label: FrameBorder;
  display: block;
  width: var(--w);
  aspect-ratio: var(--preview-ratio, 1);
  position: absolute;
  top: calc(var(--ion-safe-area-top, 0px) + 1.5rem);
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 1px;
  box-shadow: 0 0 0 0.3rem #fff;
  opacity: 1;

  [data-loaded="false"] & {
    opacity: 0;
  }
`;

const Actions = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 0.6rem 1rem 0;
  top: calc(
    var(--w) * calc(1 / var(--preview-ratio)) + var(--preview-y) + 10px
  );
  overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.22s ease-in-out;

  [data-loaded="false"] & {
    opacity: 0;
  }
`;

const Close = styled.button`
  position: fixed;
  left: 1.5rem;
  color: white;
  background: transparent;
  outline-color: transparent;
  height: 3rem;
  display: grid;
  place-items: center;
  z-index: 99999;
  bottom: calc(var(--ion-safe-area-bottom, 0px) + 2.5rem);
  padding: 0 2rem;
  font-size: 1.1rem;

  svg {
    width: 70%;
    height: auto;
  }
`;

const Info = styled.div`
  color: white;
  text-align: center;
  opacity: 1;
  transition: opacity 0.22s ease-in-out;
  font-size: 0.95rem;
  margin-top: 0.8rem;

  h5 {
    font-weight: 500;
    margin: 0 0 0.2rem;
    font-size: 1.1em;
  }
  p {
    margin: 0;
    font-size: 1em;
  }

  [data-loaded="false"] & {
    opacity: 0;
  }
`;

const CaptureButton = styled.button`
  border-radius: 50%;
  background: transparent;
  outline-color: transparent;
  height: 6rem;
  width: 6rem;
  margin-bottom: calc(var(--ion-safe-area-bottom, 0px) + 1rem);
`;

const FlashToggle = styled.button`
  border-radius: 50%;
  background: #000000;
  outline-color: transparent;
  position: absolute;
  right: 4rem;
  color: white;
  bottom: calc(var(--ion-safe-area-bottom, 0px) + 2.5rem);
  width: 3rem;
  height: 3rem;
`;

const CameraPreviewFrame: FC = () => {
  const [
    { title, description, flashMode, show, loaded },
    { stop, init, capture, toggleFlashMode }
  ] = useBloc(CameraPreviewBloc);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        void init();
      }, 20);
    } else {
      stop();
    }
  }, [show]);

  return (
    <Wrap id="cameraWrap" data-show={String(show)} data-loaded={String(loaded)}>
      <CameraTarget id="cameraPreview"></CameraTarget>
      <Frame id="frameBorder" />
      <Actions>
        <Info>
          <h5>{title}</h5>
          <p>{description}</p>
        </Info>
        <CaptureButton onClick={() => void capture()}>
          <CameraButtonIcon />
        </CaptureButton>
        {flashMode && (
          <FlashToggle onClick={() => void toggleFlashMode()}>
            {flashMode === "torch" ? (
              <IconFlashLightningEnabledTurnedOn />
            ) : (
              <IconFlashLightningDisabledTurnedOff />
            )}
          </FlashToggle>
        )}
        <Close id="cameraClose" onClick={stop}>
          <Translate msg="cancel" />
        </Close>
      </Actions>
    </Wrap>
  );
};

export default CameraPreviewFrame;
